import React, { useEffect, useRef, useState } from "react";
import "../../styles/privacyPolicy.css";
import "locomotive-scroll/locomotive-scroll.css";

import { IoMdClose } from "react-icons/io";

function PrivacyPolicy({ show, setShow }) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      function handleClickOutside(event) {
        if (
          containerRef.current &&
          !containerRef.current.contains(event.target)
        ) {
          console.log(true);
          setShow(false);
        }
      }

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return (
    <div id="privacyPolicy" className={`privacyPolicy ${show ? "show" : ""}`}>
      <div ref={containerRef} className="container">
        <div className="header">
          <div className="title">
            <p className="label">PRIVACY AND POLICY</p>
            <p className="date">Updated at July 9th, 2024</p>
          </div>
          <div className="action">
            <IoMdClose onClick={() => setShow(false)} />
          </div>
        </div>
        <div className="content ignore-scroll">
          <p className="paragraph">
            ARC Corporation (“we,” “our,” or “us”) is committed to protecting
            your privacy. This Privacy Policy explains how your personal
            information is collected, used, and disclosed by ARC Corporation.
            This Privacy Policy applies to our website, and its associated
            subdomains (collectively, our “Service”) alongside our application,
            ARC Corporation. By accessing or using our Service, you signify that
            you have read, understood, and agree to our collection, storage,
            use, and disclosure of your personal information as described in
            this Privacy Policy and our Terms of Service.
          </p>

          <div className="title-desc">
            <p className="title">Definitions and key terms</p>
            <p className="desc">
              To help explain things as clearly as possible in this Privacy
              Policy, every time any of these terms are referenced, are strictly
              defined as:
            </p>
          </div>

          <ul>
            <li>
              <span className="bold">Cookie: </span>small amount of data
              generated by a website and saved by your web browser. It is used
              to identify your browser, provide analytics, remember information
              about you such as your language preference or login information.
            </li>
            <li>
              <span className="bold">Company: </span>when this policy mentions
              “Company,” “we,” “us,” or “our,” it refers to ARC Corporation,
              (6028 Stonybook CT, Topeka, KS 66614), that is responsible for
              your information under this Privacy Policy.
            </li>
            <li>
              <span className="bold">Country: </span>where ARC Corporation or
              the owners/founders of ARC Corporation are based, in this case is
              United States of America.
            </li>
            <li>
              <span className="bold">Customer: </span>refers to the company,
              organization, or person that signs up to use the ARC Corporation
              Service to manage the relationships with your consumers or service
              users.
            </li>
            <li>
              <span className="bold">Device: </span>any internet connected
              device such as a phone, tablet, computer, or any other device that
              can be used to visit ARC Corporation and use the services.
            </li>
            <li>
              <span className="bold">IP address: </span>Every device connected
              to the Internet is assigned a number known as an Internet protocol
              (IP) address. These numbers are usually assigned in geographic
              blocks. An IP address can often be used to identify the location
              from which a device is connecting to the Internet.
            </li>
            <li>
              <span className="bold">Personnel: </span>refers to those
              individuals who are employed by ARC Corporation or are under
              contract to perform a service on behalf of one of the parties.
            </li>
            <li>
              <span className="bold">Personal Data: </span>any information that
              directly, indirectly, or in connection with other information —
              including a personal identification number — allows for the
              identification or identifiability of a natural person.
            </li>
            <li>
              <span className="bold">Service: </span>refers to the service
              provided by ARC Corporation as described in the relative terms (if
              available) and on this platform.
            </li>
            <li>
              <span className="bold">Third-party service: </span>refers to
              advertisers, contest sponsors, promotional and marketing partners,
              and others who provide our content or whose products or services
              we think may interest you.
            </li>
            <li>
              <span className="bold">Website: </span>ARC Corporation’s site,
              which can be accessed via this URL: https://goarccorp.com/
            </li>
            <li>
              <span className="bold">You: </span>a person or entity that is
              registered with ARC Corporation to use the Services.
            </li>
          </ul>

          <div className="title-desc">
            <p className="title">Information Automatically Collected</p>
            <p className="desc">
              There is some information like your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our platform. This information may be
              used to connect your computer to the Internet. Other information
              collected automatically could be a login, e-mail address,
              password, computer and connection information such as browser
              plug-in types and versions and time zone setting, operating
              systems and platforms, purchase history, (we sometimes aggregate
              with similar information from other Users), the full Uniform
              Resource Locator (URL) clickstream to, through and from our
              website that may include date and time; cookie number; parts of
              the site you viewed or searched for; and the phone number you used
              to call our Customer Services. We may also use browser data such
              as cookies, Flash cookies (also known as Flash Local Shared
              Objects) or similar data on certain parts of our website for fraud
              prevention and other purposes. During your visits, we may use
              software tools such as JavaScript to measure and collect session
              information including page response times, download errors, length
              of visits to certain pages, page interaction information (such as
              scrolling, clicks, and mouse-overs), and methods used to browse
              away from the page. We may also collect technical information to
              help us identify your device for fraud prevention and diagnostic
              purposes.
            </p>
            <p className="desc">
              We automatically collect certain information when you visit, use
              or navigate the platform. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about who and when you use our and other technical
              information. This information is primarily needed to maintain the
              security and operation of our platform, and for our internal
              analytics and reporting purposes.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Personnel</p>
            <p className="desc">
              If you are an ARC Corporation worker or applicant, we collect
              information you voluntarily provide to us. We use the information
              collected for Human Resources purposes in order to administer
              benefits to workers and screen applicants. You may contact us in
              order to (1) update or correct your information, (2) change your
              preferences with respect to communications and other information
              you receive from us, or (3) receive a record of the information we
              have relating to you. Such updates, corrections, changes, and
              deletions will have no effect on other information that we
              maintain, or information that we have provided to third parties in
              accordance with this Privacy Policy prior to such update,
              correction, change, or deletion.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Sale of Business</p>
            <p className="desc">
              We reserve the right to transfer information to a third party in
              the event of a sale, merger, or other transfer of all or
              substantially all of the assets of ARC Corporation or any of its
              Corporate Affiliates (as defined herein), or that portion of ARC
              Corporation or any of its Corporate Affiliates to which the
              Service relates, or in the event that we discontinue our business
              or file a petition or have filed against us a petition in
              bankruptcy, reorganization, or similar proceeding, provided that
              the third party agrees to adhere to the terms of this Privacy
              Policy.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Affiliates</p>
            <p className="desc">
              We may disclose information (including personal information) about
              you to our Corporate Affiliates. For purposes of this Privacy
              Policy, "Corporate Affiliate" means any person or entity which
              directly or indirectly controls, is controlled by, or is under
              common control with ARC Corporation, whether by ownership or
              otherwise. Any information relating to you that we provide to our
              Corporate Affiliates will be treated by those Corporate Affiliates
              in accordance with the terms of this Privacy Policy.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Governing Law</p>
            <p className="desc">
              This Privacy Policy is governed by the laws of the United States
              of America without regard to its conflict of laws provision. You
              consent to the exclusive jurisdiction of the courts in connection
              with any action or dispute arising between the parties under or in
              connection with this Privacy Policy except for those individuals
              who may have rights to make claims under Privacy Shield, or the
              Swiss-US framework. The laws of the United States of America,
              excluding its conflicts of law rules, shall govern this Agreement
              and your use of the website. Your use of the website may also be
              subject to other local, state, national, or international laws. By
              using ARC Corporation or contacting us directly, you signify your
              acceptance of this Privacy Policy. If you do not agree to this
              Privacy Policy, you should not engage with our website or use our
              services. Continued use of the website, direct engagement with us,
              or following the posting of changes to this Privacy Policy that do
              not significantly affect the use or disclosure of your personal
              information will mean that you accept those changes.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Your Consent</p>
            <p className="desc">
              We've updated our Privacy Policy to provide you with complete
              transparency into what is being set when you visit our site and
              how it's being used. By using our ARC Corporation, registering an
              account, or making a purchase, you hereby consent to our Privacy
              Policy and agree to its terms.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Links to Other Websites</p>
            <p className="desc">
              This Privacy Policy applies only to the Services. The Services may
              contain links to other websites not operated or controlled by ARC
              Corporation. We are not responsible for the content, accuracy, or
              opinions expressed in such websites, and such websites are not
              investigated, monitored, or checked for accuracy or completeness
              by us. Please remember that when you use a link to go from the
              Services to another website, our Privacy Policy is no longer in
              effect. Your browsing and interaction on any other website,
              including those that have a link on our platform, are subject to
              that website’s own rules and policies. Such third parties may use
              their own cookies or other methods to collect information about
              you.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">Cookies</p>
            <p className="desc">
              ARC Corporation uses "Cookies" to identify the areas of our
              website that you have visited. A Cookie is a small piece of data
              stored on your computer or mobile device by your web browser. We
              use Cookies to enhance the performance and functionality of our
              website but are non- essential to their use. However, without
              these cookies, certain functionality like videos may become
              unavailable or you would be required to enter your login details
              every time you visit the website as we would not be able to
              remember that you had logged in previously. Most web browsers can
              be set to disable the use of Cookies. However, if you disable
              Cookies, you may not be able to access functionality on our
              website correctly or at all. We never place Personally
              Identifiable Information in Cookies.
            </p>
          </div>

          <div className="title-desc">
            <p className="title">
              Blocking and Disabling Cookies and Similar Technologies
            </p>
            <p className="desc">
              Wherever you're located you may also set your browser to block
              cookies and similar technologies, but this action may block our
              essential cookies and prevent our website from functioning
              properly, and you may not be able to fully utilize all of its
              features and services. You should also be aware that you may also
              lose some saved information (e.g. saved login details, site
              preferences) if you block cookies on your browser. Different
              browsers make different controls available to you.
            </p>
          </div>
        </div>
        <div className="action">
          <button onClick={() => setShow(false)}>I Understand</button>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
